import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'canvas', 'collapse' ]


  initialize() {
    if (!this.data.has('klass')) {
      this.data.set('klass', 'navbar-responsive')
    }
  }

  open() {
    if (!this.is_class_contain(event)) {
      const klass = this.data.get('klass')

      this.canvasTarget.addEventListener('animationend', (event) => {
        this.canvasTarget.classList.remove('slideIn')
      }, { once: true })
      this.canvasTarget.classList.add(klass, 'slideIn', 'show')
      this.collapseTarget.classList.add('show')
    }
  }

  close() {
    if (!event.target.classList.contains('navbar-wrapper')) {
      return
    }

    const klass = this.data.get('klass')

    if (this.is_class_contain(event)) {
      this.canvasTarget.addEventListener('animationend', (event) => {
        this.canvasTarget.classList.remove(klass, 'slideOut', 'show')
        this.collapseTarget.classList.remove('show')
      }, { once: true })
      this.canvasTarget.classList.add('slideOut')
    }
  }

  is_class_contain(event) {
    return this.canvasTarget.classList.contains(this.data.get('klass'))
  }
}
